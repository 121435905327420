import {Container, Grid, TextField} from '@mui/material';
import {useState} from 'react';
import FastBlockInfo from './FastBlockInfo';
import Tabs from './Tabs';
import {Button} from './styled';

type EmptySearchBlockProps = {
    themeSearch: string,
    onChange: (elm: any) => void;
    handlerSubmit: () => void;
    setSearchQuery?: (query: string) => void;
};

const EmptySearchBlock = ({
    themeSearch,
    onChange,
    handlerSubmit,
    setSearchQuery
}: EmptySearchBlockProps) => {
    const [inputValue, setInputValue] = useState('');

    const inputChangeHandler = (elm: any) => {
        const value = elm.target.value;
        setInputValue(value);
        onChange(elm);
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handlerSubmit();
        }
    };

    const handleClick = () => {
        if (inputValue.length > 0) {
            handlerSubmit();
        }
    };

    const setUserQueryByAction = (query: string) => {
        setInputValue(query);
        if (setSearchQuery) {
            setSearchQuery(query);
        }
        handleClick();
    }

    return (
        <Container
            sx={{
                p: '12px'
            }}
        >
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={inputValue.length === 0 ? 12 : 9} sm={inputValue.length === 0 ? 12 : 10}>
                    <TextField
                        sx={{
                            border: '3px solid #4c3e77',
                            backgroundColor: themeSearch === 'dark' ? '#120e1c' : '#c9bfe3',
                            borderRadius: '5px',
                            fontFamily: 'Roboto',
                            fontWeight: 500,
                            fontSize: { xs: "14px", md: '24px' },
                            lineHeight: '28px',
                            color: themeSearch === 'dark' ? 'rgba(255, 255, 255, 0.5)' : '#4d4078'
                        }}
                        id='url'
                        onChange={inputChangeHandler}
                        onKeyDown={handleKeyDown}
                        label='Искать'
                        fullWidth
                    />
                </Grid>
                {inputValue.length > 0 && (
                    <Grid item md={2} sm={2} xs={3}>
                        <Button
                            sx={{
                                mt: 0,
                                height: { xs: '60px', md: '60px' }
                            }}
                            fullWidth={true}
                            size={'large'}
                            variant='contained'
                            onClick={handleClick}
                        >
                            Поиск
                        </Button>
                    </Grid>
                )}
            </Grid>
            {inputValue.length > 0 ?
                <Tabs />
                :
                <FastBlockInfo setValue={setUserQueryByAction} />
            }
        </Container>
    );
};

export default EmptySearchBlock;