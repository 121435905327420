import {Box, Container, Typography} from "@mui/material";

interface FooterProps {
    themeFooter: string
}

const Footer = (props: FooterProps) => {
    return (
        <Container sx={{
            textAlign: "center",
            mb: 1,
        }}>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <Typography
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        fontWeight: 200,
                        fontSize: { xs: "8px", sm: "11px", md: "12px", lg: "16px" },
                        lineHeight: { xs: "10px", sm: "22px" },
                        color: props.themeFooter === 'dark' ? 'white' : "black"
                    }}
                >
                    Условия использования
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 200,
                        fontSize: { xs: "8px", sm: "11px", md: "12px", lg: "16px" },
                        lineHeight: { xs: "10px", sm: "22px" },
                        color: props.themeFooter === 'dark' ? 'white' : "black"
                    }}
                >
                    © 2023–{new Date().getFullYear()} «RELL TECH»
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 200,
                        fontSize: { xs: "8px", sm: "11px", md: "12px", lg: "16px" },
                        lineHeight: { xs: "10px", sm: "22px" },
                        color: props.themeFooter === 'dark' ? 'white' : "black"
                    }}>
                    version: pre-alpha-0.0.1
                </Typography>
            </Box>
        </Container>
    )
}

export default Footer;