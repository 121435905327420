import {Box, Typography} from '@mui/material';
import {useState} from 'react';
import {RateFastInfo} from '../../types/types';
import {getExchangeRateFastInfo} from "../../api/controllers/web-controller";

// 105,756
// FIXME переписать этот кусок
const moneyViewHelper = (value: string) => {
	if (value === null || value === undefined) return "";
	const arr = value.split(",")
	if (arr.length === 1) {
		return value;
	}
	const head = arr[0];
	let tail = arr[1];
	if (tail.length === 1) {
		tail = tail + "0";
	}
	if (tail.length > 2) {
		let tmp = (parseFloat("0." + tail) * 100);
		tmp = Math.round(tmp) / 100;
		tail = (tmp + "").replace("0.", "").substring(0, 2);
	}
	return head + "," + tail;
}

export interface FastBlockInfoProps {
	setValue: (value: string) => void;
}

const FastBlockInfo = ({ setValue }: FastBlockInfoProps) => {
	const [fastInfo, setFastInfo] = useState<RateFastInfo | undefined>();

	useState(() => {
		getExchangeRateFastInfo()
			.then(response => {
				setFastInfo(response.data);
			})
			.catch(err => {
			});
	})

	return (
		<Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					mt: '5px',
					px: { xs: '3px', sm: '12px' }
				}}
			>
				<Typography
					className={'underover'}
					sx={{
						cursor: 'pointer',
						fontSize: { xs: "12px", sm: "14px", md: "16px" },
						lineHeight: { xs: "12px", sm: "22px" }
					}}
					onClick={() => setValue("Последние новости")}
				>
					Последние новости
				</Typography>
				<Box sx={{ display: 'flex' }}>
					<Typography
						sx={{
							fontSize: { xs: "12px", sm: "14px", md: "16px" },
							lineHeight: { xs: "12px", sm: "22px" },
							marginRight: { xs: '6px', sm: '12px' }
						}}
					>
						Курс валют
					</Typography>
					<Box sx={{
						display: 'flex',
						flexDirection: 'column',
						fontSize: { xs: "12px", sm: "14px", md: "16px" },
						lineHeight: { xs: "12px", sm: "22px" }
					}}>
						{
							fastInfo != null && fastInfo.list.filter(r => r.id === "EUR" || r.id === "USD").map((item, id) => (
								<Typography
									sx={{
										fontSize: { xs: "12px", sm: "14px", md: "16px" },
										lineHeight: { xs: "12px", sm: "22px" }
									}}
									key={id}
									component={"span"}
								>
									{item.id} {moneyViewHelper(item.value)}
								</Typography>
							))
						}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default FastBlockInfo;
