import React from 'react';

import {Box, Grid, Pagination, Stack, TextField} from '@mui/material';

import {QueryResultDto} from '../../types/types';
import ItemSearchResult from './element/ItemSearchResult';
import {Button} from './styled';

type RichSearchBlockProps = {
	themeSearch: string;
	result: QueryResultDto | undefined;
	submitFn: () => void;
	changeFn: (el: any) => void;
	query: string;
	page: number;
	handlerPagination: (event: any, value: any) => void;
};

const RichSearchBlock = ({
	themeSearch,
	result,
	submitFn,
	changeFn,
	page,
	query,
	handlerPagination
}: RichSearchBlockProps) => {


	return (
		<Stack
			sx={{
				p: '12px'
			}}
		>
			<Grid container spacing={2} sx={{ mt: 2 }}>
				<Box sx={{ display: 'flex', gap: '10px', width: '100%' }}>
					<TextField
						sx={{
							border: '3px solid #4c3e77',
							backgroundColor: themeSearch === 'dark' ? '#120e1c' : '#c9bfe3',
							borderRadius: '5px',
							fontFamily: 'Roboto',
							fontWeight: 500,
							fontSize: { xs: "14px", md: '24px' },
							lineHeight: '28px',
							color: themeSearch === 'dark' ? 'rgba(255, 255, 255, 0.5)' : '#4d4078'
						}}
						id='url'
						value={query}
						onChange={changeFn}
						onKeyDown={e => {
							if (e.key === 'Enter') submitFn();
						}}
						label='Искать'
						variant='outlined'
						fullWidth
					/>
					<Button
						sx={{
							mt: 0,
							height: { xs: '60px', md: '60px' }
						}}
						fullWidth={true}
						size={'large'}
						variant='contained'
						onClick={submitFn}
					>
						Поиск
					</Button>
				</Box>
			</Grid>
			<Box>
				{result && result.size > 0 ? (
					<ul
						style={{
							marginTop: '50px'
						}}
					>
						{result.content.map((item, index) => (
							<ItemSearchResult
								themeResault={themeSearch}
								key={index}
								data={item}
							/>
						))}
					</ul>
				) : <span>Нет результатов по данному запросу</span>
				}
			</Box>
			{result && result.totalPage > 1 && (
				<Pagination
					count={result?.totalPage}
					color="primary"
					sx={{
						my: 2
					}}
					page={page}
					size='small'
					onChange={handlerPagination}
				/>
			)}
		</Stack>
	);
};

export default RichSearchBlock;
