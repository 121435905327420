import MuiButton from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {styled} from '@mui/material/styles';

export const Button = styled(MuiButton)(props => ({
	display: 'flex',
	justifyContent: 'flex-start',
	background: '#4C3E77',
	color: '#FFFFFF',
	paddingRight: 0,
	width: '150px',
	height: '60px',
	WebkitClipPath: 'polygon(0% 0%, 100% 0, 100% 70%, 90% 100%, 0% 100%)',
	clipPath: 'polygon(0 0, 100% 0, 100% 0%, 70% 100%, 0 100%)',
	'@media (max-width: 599px)': {
		width: '116px',
		height: '30px',
		fontSize: '13px',
		paddingLeft: '10px',
	},
}));

export const StyledInput = styled(TextField)(props => ({
	background: '#120E1C',
	width: '100%',
	border: '3px solid #4C3E77',
	height: '54px',
	'@media (max-width: 680px)': {
		height: '30px',
	},
	input: {
		color: 'white',
		position: 'unset',
		padding: '13px',
		'@media (max-width: 680px)': {
			padding: '0 4px',
		},
	},
	label: {
		visibility: 'hidden',
	},
	focus: {
		position: 'unset',
		outline: 'none',
	},
	'&:focus': {
		boxShadow: 'none',
		border: '3px solid #4C3E77',
	},
}));
