import {Box} from "@mui/material";


export type HeaderProperties = {
    themeHeader: string;
    switchThemeHandler: () => void;
}

const Header = ({ themeHeader, switchThemeHandler }: HeaderProperties) => {

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "50px"
            }}
        >
            {themeHeader === 'dark' ?
                <img style={{ display: "block" }} src="../static/logo.svg" alt="logo" width={100} />
                :
                <img style={{ display: "block" }} src="../static/logo-dark.svg" alt="logo-dark" width={100} />
            }
            <Box>
                <label className="switch">
                    <input type="checkbox" onClick={() => switchThemeHandler()} />
                    <span className="slider round"></span>
                </label>
            </Box>
        </Box>
    );
}

export default Header;