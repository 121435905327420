import {$api, config} from "../index";
import {CreateQuery, QueryResultDto, RateFastInfo} from "../../types/types";


export const createRequest = (dto: CreateQuery) => {
    return $api.get<QueryResultDto>(`/search?q=${encodeURIComponent(dto.query)}`, { headers: config() });
}

export const createRequestWithPageable = (dto: CreateQuery, page?: number) => {
    if (page != null) {
        return $api.get<QueryResultDto>(`/search?q=${encodeURIComponent(dto.query)}&page=${page}`, { headers: config() });
    }
    return $api.get<QueryResultDto>(`/search?q=${dto.query}`, { headers: config() });
}

export const metricRequest = (url: string) => {
    return $api.post<QueryResultDto>(`/metrics?url=${url}`, null, { headers: {...config(), ...{"Content-Type": "text/plain"}} });
}


// --> https://rell.ru/exchange-rate-service-api/fast_info
// <-- {"id":"14.10.2024","list":[{"id":"AUD","value":"64,7887"},{"id":"AZN","value":"56,5109"},{"id":"GBP","value":"125,3599"},{"id":"AMD","value":"0,24813"},{"id":"BYN","value":"29,2607"},{"id":"BGN","value":"53,6969"},{"id":"BRL","value":"17,2117"},{"id":"HUF","value":"0,262368"},{"id":"VND","value":"0,00397388"},{"id":"HKD","value":"12,3832"},{"id":"GEL","value":"35,2481"},{"id":"DKK","value":"14,0795"},{"id":"AED","value":"26,1589"},{"id":"USD","value":"96,0686"},{"id":"EUR","value":"105,1095"},{"id":"EGP","value":"1,97779"},{"id":"INR","value":"1,14288"},{"id":"IDR","value":"0,00613543"},{"id":"KZT","value":"0,195723"},{"id":"CAD","value":"69,8478"},{"id":"QAR","value":"26,3925"},{"id":"KGS","value":"1,12361"},{"id":"CNY","value":"13,4752"},{"id":"MDL","value":"5,43719"},{"id":"NZD","value":"58,573"},{"id":"NOK","value":"8,90621"},{"id":"PLN","value":"24,5048"},{"id":"RON","value":"21,1274"},{"id":"XDR","value":"128,5753"},{"id":"SGD","value":"73,5989"},{"id":"TJS","value":"9,01004"},{"id":"THB","value":"2,8839"},{"id":"TRY","value":"2,81042"},{"id":"TMT","value":"27,4482"},{"id":"UZS","value":"0,00752399"},{"id":"UAH","value":"2,33135"},{"id":"CZK","value":"4,15468"},{"id":"SEK","value":"9,24044"},{"id":"CHF","value":"112,2166"},{"id":"RSD","value":"0,897863"},{"id":"ZAR","value":"5,50331"},{"id":"KRW","value":"0,0711936"},{"id":"JPY","value":"0,646274"}]}

export const getExchangeRateFastInfo = () => {
    return $api.get<RateFastInfo>(`/exchange-rate-service-api/fast_info`, { headers: config() });
}

