import {Box, Typography} from '@mui/material';
import {useState} from 'react';

const Tabs = () => {
	const [tabs] = useState<Array<string>>([
		'Поиск',
		'Картинки',
		'Видео',
		'Карты',
	]);

	return (
		<Box>
			<Box sx={{
				display: 'flex',
				gap: '8px',
				mt: '5px'
			}}>
				{tabs.map((el, id) => (
					<Typography
						sx={{
							fontSize: { xs: "10px", sm: "11px", md: "12px" },
							lineHeight: { xs: "10px", sm: "22px" }
						}}
						key={id}
					>
						{el}
					</Typography>
				))}
			</Box>
		</Box>
	);
};

export default Tabs;
