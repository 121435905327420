import {Container, createTheme, CssBaseline, ThemeProvider} from '@mui/material';
import SearchBlock from '../components/SearchBlock';
import AppLayout from '../layouts/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
import {useState} from "react";
import {Theme} from "@mui/material/styles/createTheme";

const darkTheme = createTheme({
	palette: {
		mode: 'dark',
	},
	typography: {
		body1: {
			colorScheme: "white",
			color: "white"
		}
	}
});


const lightTheme = createTheme({
	palette: {
		mode: 'light',
	},
	typography: {
		body1: {
			colorScheme: "#4c3e77",
			color: "#4c3e77"
		}
	}
});


const MainPage = () => {

	const [theme, setTheme] = useState<Theme>(darkTheme);

	const switchThemeHandler = () => {
		if (theme.palette.mode === 'dark') {
			setTheme(lightTheme);
		} else {
			setTheme(darkTheme);
		}
	}

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<AppLayout>
				<Container
					sx={{
						flexGrow: 1,
						display: 'flex',
						justifyContent: 'space-between',
						margin: '0 auto',
						flexDirection: 'column',
						height: "100vh"
					}}
				>
					<Header
						themeHeader={theme.palette.mode}
						switchThemeHandler={switchThemeHandler}
					/>
					<SearchBlock themeSearch={theme.palette.mode} />
					<Footer themeFooter={theme.palette.mode} />
				</Container>
			</AppLayout>
		</ThemeProvider>
	);
};

export default MainPage;
