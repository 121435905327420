import React, {useEffect, useState} from "react";
import {getDefaultPageable, Pageable, QueryResultDto} from "../types/types";
import {createRequest, createRequestWithPageable} from "../api/controllers/web-controller";
import EmptySearchBlock from "./search/EmptySearchBlock";
import RichSearchBlock from "./search/RichSearchBlock";

interface SearchBlockProps {
    themeSearch: string;
}

const SearchBlock = (props: SearchBlockProps) => {

    const [newSession, setNewSession] = useState<boolean>(true);
    const [query, setQuery] = useState<string>('');
    // default/video/image/books and etc
    const [category, setCategory] = useState<string>('default');
    const [result, setResult] = useState<QueryResultDto>();

    const [page, setPage] = React.useState<number>(1);
    const [pageable, setPageable] = React.useState<Pageable>(getDefaultPageable());

    useEffect(() => {
        if (query.length > 0) {
            createRequestWithPageable({ query: query }, pageable.page).then(response => {
                setResult(response.data);
                setNewSession(false);
            }).catch(err => {
                console.log(err);
                setNewSession(false);
            });
        }
    }, [pageable])

    const handlerPagination = (event: any, value: any) => {
        console.log(value);
        setPage(value);
        let newPageable: Pageable = getDefaultPageable();
        newPageable['page'] = value - 1;
        setPageable({ ...pageable, ...newPageable });
    }

    const handlerSubmit = () => {
        if (query.length > 0) {
            createRequest({ query: query }).then(response => {
                console.log(response.data);
                setPage(1);
                setResult(response.data);
                setNewSession(false);
            }).catch(err => {
                console.log(err);
                alert("Произошла ошибка. Мы уже чиним =)");
            });
        }
    }

    /**
     * Отображение в переменной значения value input
     * @param elm input
     */
    const changeLink = (elm: any) => {
        setQuery(elm.target.value);
    }

    const setCustomSearchQuery = (query: string) => {
        setQuery(query);
        createRequest({ query: query }).then(response => {
            console.log(response.data);
            setResult(response.data);
            setNewSession(false);
        }).catch(err => {
            console.log(err);
            alert("Произошла ошибка. Мы уже чиним =)");
            // TODO удалить в бою
            setNewSession(false);
        });
    }

    return (
        newSession ?
            <EmptySearchBlock
                themeSearch={props.themeSearch}
                onChange={changeLink}
                handlerSubmit={handlerSubmit}
                setSearchQuery={setCustomSearchQuery}
            />
            :
            <RichSearchBlock
                themeSearch={props.themeSearch}
                query={query}
                result={result}
                submitFn={handlerSubmit}
                changeFn={changeLink}
                page={page}
                handlerPagination={handlerPagination}
            />
    )
}

export default SearchBlock;