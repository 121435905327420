import {PageableContentResultDto} from "../../../types/types";
import {metricRequest} from "../../../api/controllers/web-controller";

import {Box, Link, Typography} from "@mui/material";

export type ItemSearchResultProps = {
    themeResault: string,
    data: PageableContentResultDto,
}

const ItemSearchResult = ({ themeResault, data }: ItemSearchResultProps) => {

    const clickHandler = (url: string) => {
        try {
            metricRequest(url).then().catch();
        } catch (e) {

        }
    }

    return (
        <Box
            key={data.url}
            style={{
                marginBottom: "28px"
            }}
        >
            <Link
                onClick={() => clickHandler(data.url)}
                target="_self"
                href={data.url}
                sx={{
                    display: "flex",
                    padding: '2px',
                    textDecoration: "none",
                }}
                // Выдаёт без этого - warming, можно убрать, при вёрстке я его поставил, так как раздражал
                rel="noreferrer"
            >
                <img src={data.faviconUrl}
                    width={17}
                    height={17}
                    alt="iconrequest"
                    style={{
                        height: "17px",
                        margin: "2px 18px 5px 0"
                    }}
                />
                <Typography
                    component={'h2'}
                    sx={{
                        fontWeight: 700,
                        fontSize: { xs: "12px", sm: "14px", md: "17px" },
                        lineHeight: { xs: '17px', sm: "22px" },
                        color: themeResault === 'dark' ? 'white' : "black"
                    }}
                >
                    {data.title}
                </Typography>
            </Link>
            <Link
                href={data.url}
                onClick={() => clickHandler(data.url)}
                target="_self"
                role="text"
                sx={{
                    display: "block",
                    fontWeight: 200,
                    fontSize: { xs: "8px", sm: "11px", md: "12px" },
                    lineHeight: { xs: "10px", sm: "22px" },
                    opacity: 0.7,
                    marginLeft: "38px",
                    color: themeResault === 'dark' ? 'white' : "black"
                }}
                // Тоже самое, ликвидация раздражающего warming
                rel="noreferrer"
            >
                {data.title}
            </Link>
            <Typography
                component={'p'}
                sx={{
                    maxWidth: "650px",
                    fontWeight: 300,
                    fontSize: { xs: "12px", sm: '14px', md: "17px" },
                    lineHeight: "22px",
                    marginLeft: "38px",
                    color: themeResault === 'dark' ? 'white' : "black"
                }}
            >
                {data.shortContent}
            </Typography>
        </Box>
    );
}


export default ItemSearchResult;