import * as React from "react";

export type PageableContentResultDto = {
    title?: string;
    favicon?: string;
    content?: string;
    url: string;
    shortContent?: string;
    faviconUrl?: string;
    type?: string;
    range?: number | null;
}

export type QueryResultDto = {
    page: number;
    totalPage: number;
    size: number;
    content: PageableContentResultDto[]
}

export type CreateQuery = {
    query: string;
}


//https://yandex.ru/dev/weather/doc/ru/concepts/history#parameters
export type WeatherLocationInfo = {
    temperature: number;
    cloud_cover: number;
    precipitation_type: number;
}

export type Rate = {
    code: string;
    value: number;
}

export type RateV2 = {
    id: string; // is code
    value: string; // is value as 64.7887
}


// {"id":"14.10.2024","list":[{"id":"AUD","value":"64,7887"}
export type RateFastInfo = {
    id: string;
    list: RateV2[];
}

// Fast info for user by location
export type FastBlockInfoDto = {
    weather: WeatherLocationInfo;
    expensive_condition: number;
    rates: Rate[]; // max 2 items
    location: string;
}

export type NavbarModalItem = {
    name: string;
    description: string;
    href: string;
    icon: React.ForwardRefExoticComponent<React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & { title?: string, titleId?: string } & React.RefAttributes<SVGSVGElement>>;
}

export type NavbarModalProps = {
    items: NavbarModalItem[];
    callsToActions: NavbarModalItem[];
}

export type Pageable = {
    page: number;
    size: number;
    sort?: string[];
}

export const getDefaultPageable = (): Pageable => {
    return { page: 0, size: 20, sort: ["id"] }
}